import axios from 'axios';
import displayNotification from '../components/shared/DisplayNotification.js';
import actionTypes from '../constants/actionTypes';
import { analyticsEventActions, analyticsEventCategory } from '../constants/analyticEvents';
import Analytics from '../helpers/Analytics';
import { AuthCookie } from '../helpers/AuthCookie';
import { asyncHandler, safeGetProperty } from '../helpers/customMethods';
import httpClient, { xhr } from '../helpers/httpClient';
import userRequest from '../requests/userRequests';
import { resetProperties } from './propertyActionCreators';

export const signInSuccess = (data) => ({
  type: actionTypes.SIGNING_IN_SUCCESS,
  data,
});

export const signInFailure = (error) => ({
  type: actionTypes.SIGNING_IN_FAILURE,
  error,
});

const signOutSuccess = () => ({
  type: actionTypes.SIGN_OUT_SUCCESS,
});

const currentUserSuccess = (data) => ({
  type: actionTypes.CURRENT_USER_SUCCESS,
  data,
});

export const currentUserFailure = (error) => ({
  type: actionTypes.CURRENT_USER_FAILURE,
  error,
});

const passwordResetSuccess = (data) => ({
  type: actionTypes.PASSWORD_RESET_SUCCESS,
  data,
});

const passwordResetFailure = (error) => ({
  type: actionTypes.PASSWORD_RESET_FAILURE,
  error,
});

const createPasswordSuccess = (user) => ({
  type: actionTypes.CREATE_PASSWORD_SUCCESS,
  user,
});

const createPasswordFailure = (error) => ({
  type: actionTypes.CREATE_PASSWORD_FAILURE,
  error,
});

const updatePasswordSuccess = (user) => ({
  type: actionTypes.UPDATE_PASSWORD_SUCCESS,
  user,
});

const updatePasswordFailure = (error) => ({
  type: actionTypes.UPDATE_PASSWORD_FAILURE,
  error,
});

const verifyPasswordSuccess = (data) => ({
  type: actionTypes.VERIFY_PASSWORD_SUCCESS,
  data,
});

const verifyPasswordFailure = (error) => ({
  type: actionTypes.VERIFY_PASSWORD_FAILURE,
  error,
});

export const fetchCurrentUser = () => (dispatch) => {
  userRequest
    .fetchCurrentUser()
    .then((response) => response)
    .then((response) => {
      dispatch(currentUserSuccess(response.data));
    })
    .catch((error) => {
      dispatch(currentUserFailure(error.response));
    });
};

export const signInUser = (authPayload) => async (dispatch) => {
  const promise = userRequest.signIn({ user: authPayload });
  const {
    ok,
    response,
    error,
  } = await asyncHandler(promise);

  if (ok) {
    httpClient.setAuthorizationToken(response.data.token);
    xhr.setAuthorizationToken(response.data.token);

    AuthCookie.set(response.data.token);

    Analytics.event({
      category: analyticsEventCategory.authentication,
      action: analyticsEventActions.sign_in_user,
    });

    return dispatch(signInSuccess(response.data));
  }
  displayNotification(
    safeGetProperty('response.data.message', error),
    'error',
  );
  Analytics.event({
    category: analyticsEventCategory.authentication,
    action: analyticsEventActions.sign_in_user_failed,
  });
  return dispatch(signInFailure(error.response));
};

export const resetPassword = (user) => {
  const userPayload = { user };
  return (dispatch) => {
    userRequest
      .resetPassword(userPayload)
      .then((response) => {
        displayNotification('Reset Link Sent', 'info', response.data.message);
        dispatch(passwordResetSuccess(response.data));
      })
      .catch((error) => {
        displayNotification(error.response.data.message, 'error');
        dispatch(passwordResetFailure(error.response.data));
      });
  };
};

export const createPassword = (passwords) => async (dispatch) => {
  const promise = userRequest.createPassword(passwords);
  const {
    ok,
    response,
    error,
  } = await asyncHandler(promise);

  if (ok) {
    localStorage.setItem('token', response.data);
    Analytics.event({
      category: analyticsEventCategory.authentication,
      action: analyticsEventActions.creates_password,
    });
    return dispatch(createPasswordSuccess(response));
  }
  if (
    error.response.data.password &&
    Array.isArray(error.response.data.password)
  ) {
    error.response.data.password.reduce((acc, current) => {
      displayNotification(`Password ${current}`, 'error');
      return acc;
    }, {});
  }

  Analytics.event({
    category: analyticsEventCategory.authentication,
    action: analyticsEventActions.creates_password_failed,
  });
  return dispatch(createPasswordFailure(error.response.data));
};

export const updatePassword = (passwords) => async (dispatch) => {
  const promise = userRequest.updatePassword(passwords);
  const {
    ok,
    response,
    error,
  } = await asyncHandler(promise);

  if (ok) {
    Analytics.event({
      category: analyticsEventCategory.authentication,
      action: analyticsEventActions.updates_password,
    });
    return dispatch(updatePasswordSuccess(response));
  }

  Object.keys(error.response.data)
    .forEach((key) => {
      displayNotification(
        `${key.replace('_', ' ')} ${error.response.data[key]}`,
      );
    });

  Analytics.event({
    category: analyticsEventCategory.authentication,
    action: analyticsEventActions.updates_password_failed,
  });
  return dispatch(updatePasswordFailure(error.response.data));
};

export const verifyToken = async (token) => axios({
  baseURL: process.env.REACT_APP_API_URL,
  method: 'get',
  url: 'api/manager/listings?page=1',
  headers: { Authorization: `Token ${token}` },
})
  .then(() => Promise.resolve(true))
  .catch(() => Promise.resolve(false));

export const handleToken = () => async (dispatch) => {
  const isExpired = AuthCookie.isExpired();

  if (isExpired) return dispatch(logout());

  return Promise.resolve(true);
};

export const logout = () => (dispatch) => {
  AuthCookie.remove();
  httpClient.setAuthorizationToken();
  xhr.setAuthorizationToken();
  dispatch(resetProperties());
  dispatch(signOutSuccess());
  Analytics.event({
    category: analyticsEventCategory.authentication,
    action: analyticsEventActions.sign_out_user,
  });
};

export const verifyDefaultPassword = (defaultPassword) => async (dispatch) => {
  const promise = userRequest.verifyDefaultPassword(defaultPassword);
  const {
    ok,
    response,
    error,
  } = await asyncHandler(promise);

  if (ok) {
    return dispatch(verifyPasswordSuccess(response.data));
  }
  return dispatch(verifyPasswordFailure(error.response));
};

export const refreshToken = async (token) => {
  const promise = userRequest.refreshToken(token);
  const {
    ok,
    response,
  } = await asyncHandler(promise);

  if (ok) {
    httpClient.setAuthorizationToken(response.data.token);
    xhr.setAuthorizationToken(response.data.token);
    localStorage.removeItem('token');
    localStorage.setItem('token', response.data.token);
    localStorage.removeItem('token_expire_date');
    localStorage.setItem('token_expire_date', response.data.token_expire_date);
  }
};
